import styled from 'styled-components'
import HLOne from 'components/HLOne'
import WorkoutImageOne from 'images/WOIOne.jpg'
import Slider2 from 'images/slider2.jpg'
import Slider3 from 'images/slider3.jpg'
import Stripe from 'components/Stripe'
import ReactSwipeableViews from 'react-swipeable-views'
import { useDocument } from '@sb-konzept/firebase-hooks'
import Link from 'next/link'
import CheckRed from 'images/checkred.png'
import ProgramContext from 'components/ProgramContext'
import { useContext } from 'react'
import Lock from 'images/lock.png'
import SubscriptionContext from 'components/SubscriptionContext'

const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const SingleImage = styled.img`
  height: 120px;
  width: 120px;
  ${({ completed, locked }) =>
    completed || locked ? 'filter: brightness(50%);' : ''};
  border-radius: 10px;
`

const Slide = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
`

const ProgramLink = styled.a`
  color: #939393;
  font-size: 0.813rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const ImageWrapper = styled.div`
  position: relative;
`

const Checkmark = styled.div`
  position: absolute;
  background: red;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  bottom: 10px;
  left: 10px;
  z-index: 1;
`

function Image({ workout, completed, title, locked }) {
  const { value } = useDocument(workout)

  return (
    <Slide>
      <ImageWrapper>
        {completed && (
          <Checkmark>
            <img
              src={CheckRed}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Checkmark>
        )}
        {!completed && locked && (
          <Checkmark>
            <img
              src={Lock}
              style={{
                width: '50%',
                height: '50%',
                margin: '25%',
                objectFit: 'cover',
                filter: 'invert(1)'
              }}
            />
          </Checkmark>
        )}
        <SingleImage
          completed={completed}
          locked={locked}
          src={value?.workoutimage?.url}
        />
      </ImageWrapper>

      <span style={{ fontSize: '0.813rem', paddingTop: '0.5rem' }}>
        {value?.workoutname}
      </span>
    </Slide>
  )
}

function ProgramCarousel() {
  const {
    currentPhase,
    workoutIdx,
    week,
    program,
    currentWorkout,
    exerciseIdx
  } = useContext(ProgramContext)

  const { subscribed } = useContext(SubscriptionContext)

  return (
    <Wrapper>
      <Header>
        <HLOne style={{ textTransform: 'none' }}>Workouts for the Week</HLOne>
        <Link href="/program">
          <ProgramLink>View All</ProgramLink>
        </Link>
      </Header>
      <ReactSwipeableViews
        enableMouseEvents
        style={{ paddingRight: 'calc(100% - 120px - 1rem)' }}
      >
        {currentPhase?.workouts.map((workout, idx) => (
          <Image
            completed={
              idx <
              (workoutIdx % currentPhase?.workouts.length) +
                Number(exerciseIdx >= currentWorkout?.exercises.length)
            }
            locked={idx > -1 && !subscribed}
            workout={workout}
            // title={`Week ${week} Day ${idx + 1}`}
          />
        ))}
      </ReactSwipeableViews>
    </Wrapper>
  )
}

export default ProgramCarousel
