import styled from 'styled-components'

const CopyTxt = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  > a:-webkit-any-link {
    color: #c53535;
  }
`

export default CopyTxt
