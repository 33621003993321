import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Router, { useRouter } from 'next/router'
import useUserData from 'hooks/useUserData'
import { useCollection, useDocument } from '@sb-konzept/firebase-hooks'
import moment from 'moment'
import Close from 'images/close.png'
import { FieldValue } from 'utils/firebase'

const Context = React.createContext()
export default Context

export function ProgramProvider({ children }) {
  const [date, setDate] = useState(moment())
  const { value: user, ref } = useUserData()
  const { value: program, loading } = useDocument(user?.program)
  const router = useRouter()

  const timeRef = useRef()

  const [
    currentPhase,
    workoutIdx = 0,
    currentWorkoutRef,
    nextWorkout,
    week,
    phase
  ] = useMemo(() => {
    let idx = user?.workoutIdx || 0
    let iweek = 1
    let iphase = program?.phases[0]
    let i = 0
    if (!iphase) return []
    while (idx >= iphase.workouts.length * iphase.repeat) {
      i += 1
      iphase = program?.phases[i % program.phases.length]
      idx -= iphase.workouts.length * iphase.repeat
      iweek += iphase.repeat
    }
    return [
      iphase,
      idx,
      iphase.workouts[idx % iphase.workouts.length],
      idx + 1 < iphase.workouts.length * iphase.repeat
        ? iphase.workouts[(idx + 1) % iphase.workouts.length]
        : program.phases[(i + 1) % program.phases.length].workouts[0],
      iweek + Math.floor(idx / iphase.workouts.length),
      i
    ]
  }, [program, user?.workoutIdx])
  const { value: currentWorkout } = useDocument(currentWorkoutRef, {
    withRef: true
  })

  useEffect(() => {
    console.log(
      moment(user?.lastExercise?.toDate()).add(-2, 'hours'),
      moment().add(-2, 'hours')
    )
    if (
      ((typeof user?.completedExercises === 'undefined' &&
        user?.exerciseIdx > -1) ||
        (user?.completedExercises || []).length) &&
      !moment(user?.lastExercise?.toDate())
        .add(-2, 'hours')
        .isSame(moment().add(-2, 'hours'), 'day')
    ) {
      ref.set(
        {
          freeWorkoutUsed: true,
          workoutIdx: user?.workoutIdx + 1,
          exerciseIdx: -1,
          completedExercises: [],
          maxWorkoutIdx: user?.workoutIdx + 2
          /* ...(user &&
            program?.phases?.length &&
            Number(Math.floor(phase / program.phases.length)) && {
              badges: {
                [`program${program.id}`]: {
                  level: Math.max(
                    user.badges[`program${program.id}`].level || 0,
                    Math.floor(phase / program.phases.length)
                  )
                }
              }
            }) */
        },
        { merge: true }
      )
    }
  }, [router.pathname, user?.lastExercise])

  useEffect(() => {
    try {
      if (user && !user?.workoutReminder) {
        window.cordova.plugins.notification.local.getAll(notes =>
          window.cordova.plugins.notification.local.clear(
            notes
              .map(note => note.title === 'Not very beastly' && note.id)
              .filter(Boolean)
          )
        )
      } else if (
        timeRef.current &&
        !user?.lastExercise?.isEqual(timeRef.current)
      ) {
        const days = 7 - currentPhase?.workouts.length || 4
        window.cordova.plugins.notification.local.getAll(notes =>
          window.cordova.plugins.notification.local.clear(
            notes
              .map(note => note.title === 'Not very beastly' && note.id)
              .filter(Boolean)
          )
        )
        window?.cordova?.plugins?.notification?.local?.schedule({
          smallIcon: 'res://ic_launcher',
          title: "Let's get back to BEAST!",
          text: `Your last workout was more than ${days} days ago. Jump back in today.`,
          trigger: { in: days, unit: 'day' }
        })
      }
    } catch (e) {}
    timeRef.current = user?.lastExercise
  }, [user?.lastExercise, currentPhase?.workouts.length, user?.workoutReminder])

  const val = useMemo(
    () => ({
      nextWorkout,
      currentWorkout,
      currentPhase,
      program,
      exerciseIdx: user?.exerciseIdx || 0,
      workoutIdx,
      week,
      completedExercises: user?.completedExercises || []
    }),
    [currentWorkout, currentPhase, program, user, workoutIdx, week, nextWorkout]
  )
  return (
    <>
      <Context.Provider value={val}>{children}</Context.Provider>
    </>
  )
}
