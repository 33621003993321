import styled from 'styled-components'
import LogoSmall from 'images/logosmall.png'
import SliderOne from 'images/slider1.jpg'
import HeaderHeadline from 'components/HeaderHeadline'
import HeaderSubline from 'components/HeaderSubline'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { useContext } from 'react'
import { useCollection } from '@sb-konzept/firebase-hooks'
import ProgramContext from 'components/ProgramContext'

const Swiper = autoPlay(SwipeableViews)

const SliderBehind = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: red;
`

const SliderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 20%;
`

const TextWrapper = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Stripe = styled.div`
  height: 3px;
  width: 25%;
  background: #fff;
  margin: 10px 0;
`

const Headline = styled(HeaderHeadline)`
  margin: 0;
  padding: 5px 10px;
  background: black;
  border-radius: 5px;
`

const SliderOntop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: linear-gradient(rgba(132, 32, 8, 0), rgba(132, 32, 8, 0.5));
`

const LogoTop = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

const Wrapper = styled.div`
  position: relative;
  margin-left: -16px;
  margin-right: -16px;
  height: 0;
  padding-bottom: calc(25% + 25vh);
`

function Slider() {
  const { program, currentPhase } = useContext(ProgramContext)
  const { value = [] } = useCollection('dashboardsliders')

  return (
    <Wrapper>
      <Swiper
        containerStyle={{ height: '100%' }}
        interval={4000}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          left: 0,
          top: 0
        }}
      >
        {value.map((slide) => (
          <SliderBehind>
            <SliderImage src={slide.sliderimage?.url} />
          </SliderBehind>
        ))}
      </Swiper>
      <SliderOntop>
        <TextWrapper>
          <Headline>{program?.title}</Headline>
          <HeaderSubline>{currentPhase?.phasename}</HeaderSubline>
        </TextWrapper>
      </SliderOntop>
      <LogoTop>
        <img src={LogoSmall} style={{ height: '58px', width: '60px' }} />
      </LogoTop>
    </Wrapper>
  )
}

export default Slider
