import styled from 'styled-components'
import FireIcon from 'images/fire.svg'
import Link from 'next/link'
import Card from './Card'
import Button from './Button'

const PromoCardWrapper = styled(Card)`
  position: relative;

  background: linear-gradient(281deg, #b5790693 0%, #462f05 100%),
    url(${(props) => props.backgroundImage}), black;
  background-size: cover;
  background-position: center;
`

const PromoCardContent = styled.div`
  position: relative;
  z-index: 1;
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

const PromoCardTitle = styled.h2`
  max-width: 300px;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
`

const PromoCardDescription = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: white;
  opacity: 0.7;

  > p {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 36px;
    }
  }
`

const PromoCardButton = styled(Button)`
  background-color: #842008;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 10px 19px;
`

const PromoCardBackgroundIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  max-height: 120px;
  object-fit: contain;
  opacity: 0.5;
`

export default function SubscriptionPromoCard({
  title,
  description,
  backgroundImage
}) {
  return (
    <PromoCardWrapper backgroundImage={backgroundImage}>
      <PromoCardContent>
        <PromoCardTitle>{title}</PromoCardTitle>
        <PromoCardDescription>{description}</PromoCardDescription>
        <Link href="/subscription">
          <PromoCardButton>Unlock access now</PromoCardButton>
        </Link>
      </PromoCardContent>
      <PromoCardBackgroundIcon src={FireIcon} />
    </PromoCardWrapper>
  )
}
