import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import TabsNavigation from 'components/TabsNavigation'
import Slider from 'components/Slider'
import SubscriptionPromoCard from 'components/SubscriptionPromoCard'
import SubscriptionContext from 'components/SubscriptionContext'
import ProgramCarousel from 'components/ProgramCarousel'
import WorkoutDayDash from 'components/WorkoutDayDash'
import useUserData from 'hooks/useUserData'
import { useDocument, useCollection } from '@sb-konzept/firebase-hooks'
import Wrapper from 'components/ContentWrapper'
import Header from 'components/header'
import subPromoCardImage from 'images/card-background.png'
// import useCollection from '@sb-konzept/firebase-hooks/dist/firestore/useCollection'

const AddBanner = styled.a`
  width: 100vw;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -16px;
  margin-right: -16px;
  img {
    object-fit: cover;
    width: 100%;
  }
`

export default function IndexPage() {
  const { value } = useDocument('ad-banners/1')
  const { value: user } = useUserData()
  const { value: headlines } = useCollection('welcome-messages')
  const { subscriptionTier } = useContext(SubscriptionContext)
  const subtitle = useMemo(
    () =>
      headlines?.[Math.floor(headlines.length * Math.random())].message || '',
    [headlines]
  )

  return (
    <>
      <Header
        subtitle={subtitle}
        title={`Welcome Back ${user?.firstname ? ` ${user.firstname}` : ''}`}
      />
      <Wrapper footer header>
        <Slider />
        {subscriptionTier !== 'full' && (
          <SubscriptionPromoCard
            backgroundImage={subPromoCardImage}
            description="Gain access to 15+ complete workout routines and a personalized meal plan."
            title="Unlock full access now"
          />
        )}
        {value?.enabled && (
          <AddBanner
            onClick={() =>
              value?.link && cordova.InAppBrowser.open(value?.link, '_system')
            }
          >
            <img src={value?.image?.url} />
          </AddBanner>
        )}
        <ProgramCarousel />
        <WorkoutDayDash />
      </Wrapper>
      <TabsNavigation />
    </>
  )
}
