import styled from 'styled-components'

const Stripe = styled.div`
  height: 3px;
  width: 25%;
  background: #fff;
  margin: 10px 0;
`

export default Stripe
