import styled from 'styled-components'

const HLOne = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
`

export default HLOne
