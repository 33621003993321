import styled from 'styled-components'

const HeaderHeadline = styled.h1`
  font-size: 1.125rem;
  font-weight: 700;
  color: #fff;
  margin: 0;
  margin-top: 1rem;
  line-height: 1.8rem;
  padding-bottom: 6px;
`

export default HeaderHeadline
