import styled from 'styled-components'

const HeaderSubline = styled.h1`
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
  margin: 0;
`

export default HeaderSubline
