import styled from 'styled-components'
import HLOne from 'components/HLOne'
import CopyTxt from 'components/CopyTxt'
import WorkoutImageOne from 'images/WOIOne.jpg'
import { useContext } from 'react'
import ProgramContext from 'components/ProgramContext'
import Link from 'next/link'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import Button from 'components/Button'
import useUserData from 'hooks/useUserData'
import { FieldValue } from 'utils/firebase'
import SubscriptionContext from 'components/SubscriptionContext'

import CheckRed from 'images/checkred.png'

const ProgramLink = styled.a`
  color: #939393;
  font-size: 0.813rem;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const PrevButton = styled.div`
  border-bottom: 4px solid white;
  border-left: 4px solid white;
  transform: rotate(45deg) translateY(-50%);
  width: 12px;
  height: 12px;
  padding: 12px;
  position: absolute;
  left: -48px;
  top: 50%;
`
const NextButton = styled.div`
  border-top: 4px solid white;
  border-right: 4px solid white;
  transform: rotate(45deg) translateY(-50%);
  width: 12px;
  height: 12px;
  padding: 12px;
  position: absolute;
  right: -24px;
  top: 50%;
`

const Wrapper = styled.div`
  padding-top: 1rem;
`

const ImageButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  border-radius: 10px;
`

const WorkoutPlayButton = styled.div`
  && {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
    font-size: 8rem;
  }
`

const WorkoutDashMiddle = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
`

const WorkoutStartButton = styled.div`
  color: black;
  background: white;
  border-radius: 10px;
  font-size: 1.063rem;
  font-weight: 700;
  padding: 1rem;
  border: 0;
`

const PlayBtn = styled(({ className, ...props }) => (
  <PlayCircleOutlineIcon classes={{ root: className }} {...props} />
))`
  && {
    font-size: 8rem;
    fill: white;
  }
`

const Checkmark = styled.div`
  position: absolute;
  background: red;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  top: 10px;
  right: 10px;
  z-index: 1;
`

function WorkoutDayDash() {
  const {
    currentWorkout,
    week,
    workoutIdx,
    currentPhase,
    exerciseIdx,
    nextWorkout,
    completedExercises
  } = useContext(ProgramContext)
  const { subscribed } = useContext(SubscriptionContext)
  const { value, ref } = useUserData()
  const workoutFinished = exerciseIdx >= currentWorkout?.exercises.length

  return (
    <Wrapper>
      <Header>
        <HLOne style={{ textTransform: 'none' }}>Your Workout For Today</HLOne>
        <ProgramLink>
          Week {week} Day {(workoutIdx % currentPhase?.workouts.length) + 1}
        </ProgramLink>
      </Header>
      <ImageButtonWrapper>
        <Image src={currentWorkout?.workoutimage?.url} />
        {workoutFinished && (
          <Checkmark>
            <img
              src={CheckRed}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </Checkmark>
        )}
        <WorkoutDashMiddle>
          {workoutFinished && (
            <HLOne
              style={{
                fontSize: '1.4rem'
              }}
            >
              Workout complete
            </HLOne>
          )}
          {!workoutFinished && (
            <HLOne
              style={{
                fontSize: '1.6rem',
                paddingBottom: '0.8rem',
                paddingTop: '0.8rem'
              }}
            >
              {currentWorkout?.workoutname}
            </HLOne>
          )}
          {value?.workoutIdx > 0 && (
            <PrevButton
              onClick={e => {
                e.stopPropagation()
                ref.update({
                  workoutIdx: FieldValue.increment(-1),
                  exerciseIdx: -1,
                  completedExercises: [],
                  maxWorkoutIdx:
                    value?.maxWorkoutIdx || (value?.workoutIdx || 0) + 1
                })
              }}
            />
          )}
          {(value?.workoutIdx < value?.maxWorkoutIdx ||
            !value?.maxWorkoutIdx) &&
            subscribed && (
              <NextButton
                onClick={e => {
                  e.stopPropagation()
                  ref.update({
                    workoutIdx: FieldValue.increment(1),
                    exerciseIdx: -1,
                    completedExercises: [],
                    maxWorkoutIdx:
                      value?.maxWorkoutIdx || (value?.workoutIdx || 0) + 1
                  })
                }}
              />
            )}
          {!workoutFinished ? (
            <Link as="/workout" href="/workout">
              <WorkoutStartButton>
                {completedExercises.length ? 'Continue' : 'Start'} Workout
              </WorkoutStartButton>
            </Link>
          ) : (
            <>
              <HLOne
                style={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  paddingTop: '0.3rem',
                  paddingBottom: '0.8rem',
                  textTransform: 'none'
                }}
              >
                Your next Workout will be unlocked tomorrow
              </HLOne>
              <Link
                as={`workouts/${nextWorkout?.id}`}
                href="/workouts/[workoutid]"
              >
                <WorkoutStartButton>Preview next Workout</WorkoutStartButton>
              </Link>
            </>
          )}
        </WorkoutDashMiddle>
      </ImageButtonWrapper>
    </Wrapper>
  )
}

export default WorkoutDayDash
